// React
import React, { useRef, useState, useEffect } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { localize, localizeLink } from 'utilities';
import { transitionNavigate } from 'transition';

// Components
import Button from 'components/button';
import TilePattern from 'components/tilePattern';

const SubscriptionComponent = props => {
    const {
        content,
        location,
        pageContext: { labels, locale },
        type = 'default',
        onClick = null,
    } = props;

    const subscriptionRef = useRef(null);

    // Get theme
    const [theme] = useGlobal('pageTheme');

    // Currency
    const [currency] = useGlobal('currency');

    // ParentHeight
    const [subHeight, setSubHeight] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setSubHeight(
                subscriptionRef.current &&
                    subscriptionRef.current.getBoundingClientRect().height
            );
        }, 1000);
    }, []);

    return (
        <>
            <Subscription
                {...{
                    theme,
                    type,
                }}
                ref={subscriptionRef}>
                <TilePattern
                    {...{ theme, pattern: 'standard', parentHeight: subHeight }}
                />
                {type === 'default' && <Title>{content.title}</Title>}
                <Teaser>{content.teaser}</Teaser>
                <Variants>
                    {content.subscriptionTypeOverview.map(variant => (
                        <Variant key={variant.id}>
                            <span>{variant.description}</span>
                            <span>
                                {`${JSON.parse(variant.price)[currency]} ${
                                    labels.CURRENCY[currency]
                                } / ${variant.interval}`}
                            </span>
                        </Variant>
                    ))}
                </Variants>
                <ButtonWrapper>
                    <Button
                        {...{
                            label: content.faq,
                            theme,
                            type: 'secondary',
                            onClick() {
                                transitionNavigate({
                                    from: location,
                                    to: '/stories/subscription-faq/',
                                });
                            },
                        }}
                    />
                    <Button
                        {...{
                            label: content.callToAction,
                            theme,
                            arrow: true,
                            onClick() {
                                if (onClick) {
                                    onClick();
                                } else {
                                    transitionNavigate({
                                        from: location,
                                        to: localizeLink(
                                            '/subscription/',
                                            locale
                                        ),
                                        state: {
                                            subscription: {
                                                started: true,
                                            },
                                        },
                                    });
                                }
                            },
                        }}
                    />
                </ButtonWrapper>
            </Subscription>
        </>
    );
};

const Subscription = styled.div`
    ${props => s.layout.fullWidthBackground(props.theme.default())};

    ${s.responsive.property('padding-top', {
        0: 50,
        20: 80,
        30: 100,
    })};

    ${s.responsive.property('padding-bottom', {
        0: 50,
        20: '15vh',
        30: '20vh',
    })};

    /* Based on pattern */
    min-height: calc(100vw / 40 * 18);

    display: block;
    ${props =>
        props.type === 'page' &&
        css`
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
        `}

    color: ${props => props.theme.text()};
    position: relative;
    z-index: ${s.layout.zIndex.subscription};
`;

const Title = styled.div`
    ${s.layout.sectionTitle()};
    position: relative;
`;

const ButtonWrapper = styled.div`
    display: flex;

    ${s.responsive.property('flex-direction', {
        0: 'column',
        20: 'row',
    })};

    button {
        ${s.responsive.property('margin-right', {
            0: 0,
            20: 20,
        })};
        align-self: flex-start;
        margin-bottom: 20px;
    }
`;

const Teaser = styled.h2`
    ${s.grid.columns('width', {
        0: 4,
        10: 4,
        20: 6,
        30: 12,
    })};

    ${s.fonts.style('h3')};

    margin-bottom: 0.8em;
    position: relative;
    pointer-events: none;
`;

const Variants = styled.div`
    ${s.fonts.style('h11')};

    ${s.grid.columns('width', {
        0: 4,
        20: 6,
        30: 7,
    })};

    margin-bottom: 30px;
    position: relative;
    pointer-events: none;
`;

const Variant = styled.div`
    display: flex;
    justify-content: space-between;

    ${s.responsive.property('flex-direction', {
        0: 'column',
        20: 'row',
    })};

    ${s.responsive.property('margin-bottom', {
        0: '1em',
        20: '0.3em',
    })};

    span:last-of-type {
        ${s.grid.columns('width', {
            20: 4,
        })};
    }
`;

export default props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPartialSubscription } = useStaticQuery(graphql`
        query {
            allDatoCmsPartialSubscription {
                edges {
                    node {
                        locale
                        title
                        teaser
                        callToAction
                        faq
                        subscriptionTypeOverview {
                            id
                            description
                            price
                            interval
                        }
                    }
                }
            }
        }
    `);

    return (
        <SubscriptionComponent
            {...{
                content: localize(allDatoCmsPartialSubscription, locale),
                ...props,
            }}
        />
    );
};
